import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import Select, { countryData1 } from '../../Components/UI/Select/Select'
import axiosPersona from '../../Utils/personaAxios'
import { setGAEvent } from '../../Utils/googleAnalytics'
import ApiError from '../../Components/UI/Alert/ApiError'
import Layout from '../../Containers/Layout'
import Button from '../../Components/UI/Button/Button'
import Checkbox from '../../Components/UI/Checkbox/Checkbox'
import Loader from '../../Components/UI/Loader/Loader'
import Step from '../../Components/Step/Step'

class Screen4 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showErrModal: false,
            showLoader: false,
            redirect: false,
            gotoHome: false,
            showOperating: false,
            registeredIso: Cookies.get("pmx_iso"),
            operatingIso: Cookies.get("pmx_iso"),
            modalErr: "",
            redirectTo: "",
            registeredAddr1: "",
            registeredAddr2: "",
            registeredCity: "",
            registeredZip: "",
            operatingAddr1: "",
            operatingAddr2: "",
            operatingCity: "",
            operatingZip: "",
        }
    }

    componentDidMount() {
        if (Cookies.get("pmx_token") === undefined) {
            this.setState({ redirect: true, gotoHome: true })
        } else {
            setGAEvent("page_view","Busioness Info 4","Step 4")
            const savedData = Cookies.get("pmx_progress")
            if (savedData !== undefined) {
                const jsonObj = JSON.parse(savedData)
                this.setState({
                    showLoader: false,
                    registeredAddr1: (jsonObj.registeredAddress1 === undefined) ? '' : jsonObj.registeredAddress1,
                    registeredAddr2: (jsonObj.registeredAddress2 === undefined) ? '' : jsonObj.registeredAddress2,
                    registeredCity: (jsonObj.registeredCity === undefined) ? '' : jsonObj.registeredCity,
                    registeredIso: (jsonObj.registeredAddrIso === undefined) ? Cookies.get("pmx_iso") : jsonObj.registeredAddrIso,
                    registeredZip: (jsonObj.registeredZip === undefined) ? '' : jsonObj.registeredZip,
                    operatingAddr1: (jsonObj.registeredAddress1 === undefined) ? '' : jsonObj.registeredAddress1,
                    operatingAddr2: (jsonObj.registeredAddress2 === undefined) ? '' : jsonObj.registeredAddress2,
                    operatingCity: (jsonObj.registeredCity === undefined) ? '' : jsonObj.registeredCity,
                    operatingIso: (jsonObj.registeredAddrIso === undefined) ? Cookies.get("pmx_iso"): jsonObj.registeredAddrIso,
                    operatingZip: (jsonObj.registeredZip === undefined) ? '' : jsonObj.registeredZip,
                })
            } else {
                this.setState({ showLoader: false })
            }
        }
    }
    operatingAddress = (val) => {
        if (val)
            this.setState({
                operatingAddr1: "",
                operatingAddr2: "",
                operatingCity: "",
                operatingZip: "",
                showOperating: true
            })
        else
            this.setState({
                operatingAddr1: this.state.registeredAddr1,
                operatingAddr2: this.state.registeredAddr2,
                operatingCity: this.state.registeredCity,
                operatingZip: this.state.registeredZip,
                showOperating: false
            })
    }
    goBack = () => {
        const savedData = Cookies.get("pmx_progress")
        const jsonObj = JSON.parse(savedData)
        const companyInfo = {
            companyType: jsonObj.companyType,
            companyName: jsonObj.companyName,
            companyWeb: (jsonObj.companyWeb === undefined) ? "" : jsonObj.companyWeb,
            companyTradeName: (jsonObj.companyTradeName === undefined) ? "" : jsonObj.companyTradeName,
            creditSafeId: (jsonObj.creditSafeId === undefined) ? "N/A" : jsonObj.creditSafeId,
            registeredIso: jsonObj.registeredIso,
            registeredNumber: (jsonObj.registeredNumber === undefined) ? "" : jsonObj.registeredNumber,
            registerDate: jsonObj.registerDate,
            vatTax: jsonObj.vatTax,
            mra1: (jsonObj.mra1 === undefined) ? "" : jsonObj.mra1,
            mra2: (jsonObj.mra2 === undefined) ? "" : jsonObj.mra2,
            mra3: (jsonObj.mra3 === undefined) ? "" : jsonObj.mra3,
            bankLocation: (jsonObj.bankLocation === undefined) ? "" : jsonObj.bankLocation,
            bankIban: (jsonObj.bankIban === undefined) ? "" : jsonObj.bankIban,
            naceCode: (jsonObj.naceCode === undefined) ? "" : jsonObj.naceCode,
            mccCode: (jsonObj.mccCode === undefined) ? "" : jsonObj.mccCode,
            needCard: (jsonObj.needCard === undefined) ? "No" : jsonObj.needCard,
            registeredAddress1: (jsonObj.registeredAddr1 === undefined) ? "" : jsonObj.registeredAddr1,
            registeredAddress2: (jsonObj.registeredAddr2 === undefined) ? "" : jsonObj.registeredAddr2,
            registeredCity: (jsonObj.registeredCity === undefined) ? "" : jsonObj.registeredCity,
            registeredZip: (jsonObj.registeredZip === undefined) ? "" : jsonObj.registeredZip,
            registeredAddrIso: (jsonObj.registeredAddrIso === undefined) ? "" : jsonObj.registeredAddrIso,
            operAddress1: (jsonObj.operAddress1 === undefined) ? "" : jsonObj.operAddress1,
            operAddress2: (jsonObj.operAddress2 === undefined) ? "" : jsonObj.operAddress2,
            operCity: (jsonObj.operCity === undefined) ? "" : jsonObj.operCity,
            operZip: (jsonObj.operZip === undefined) ? "" : jsonObj.operZip,
            operAddrIso: (jsonObj.operAddrIso === undefined) ? "" : jsonObj.operAddrIso,
            shareHolder: (jsonObj.shareHolder === undefined) ? "" : jsonObj.shareHolder,
            page: '/business-requirements'
        }
        Cookies.set("pmx_progress", JSON.stringify(companyInfo), { expires: 365 })
        this.setState({ redirect: true, redirectTo: "/business-requirements" })
    }
    validateForm = () => {
        const { t } = this.props
        if (this.state.registeredAddr1.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_address_line1') })
        } else if (this.state.registeredCity.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_address_city') })
        } else if (this.state.registeredZip.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_address_zip') })
        } else if (this.state.operatingAddr1.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_address_line1') })
        } else if (this.state.operatingCity.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_address_city') })
        } else if (this.state.operatingZip.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_address_zip') })
        } else {
            const savedData = Cookies.get("pmx_progress")
            let jsonObj = JSON.parse(savedData)
            let redirect="/shareholder"
            if(jsonObj.companyType === "1")
                redirect="/business-success"
            const companyInfo = {
                companyType: jsonObj.companyType,
                companyName: jsonObj.companyName,
                companyWeb: (jsonObj.companyWeb === undefined) ? "" : jsonObj.companyWeb,
                companyTradeName: (jsonObj.companyTradeName === undefined) ? "" : jsonObj.companyTradeName,
                creditSafeId: (jsonObj.creditSafeId === undefined) ? "N/A" : jsonObj.creditSafeId,
                registeredIso: jsonObj.registeredIso,
                registeredNumber: (jsonObj.registeredNumber === undefined) ? "" : jsonObj.registeredNumber,
                registerDate: jsonObj.registerDate,
                vatTax: jsonObj.vatTax,
                mra1: (jsonObj.mra1 === undefined) ? "" : jsonObj.mra1,
                mra2: (jsonObj.mra2 === undefined) ? "" : jsonObj.mra2,
                mra3: (jsonObj.mra3 === undefined) ? "" : jsonObj.mra3,
                bankLocation: (jsonObj.bankLocation === undefined) ? "" : jsonObj.bankLocation,
                bankIban: (jsonObj.bankIban === undefined) ? "" : jsonObj.bankIban,
                naceCode: (jsonObj.naceCode === undefined) ? "" : jsonObj.naceCode,
                mccCode: (jsonObj.mccCode === undefined) ? "" : jsonObj.mccCode,
                needCard: (jsonObj.needCard === undefined) ? "No" : jsonObj.needCard,
                registeredAddress1: this.state.registeredAddr1,
                registeredAddress2: this.state.registeredAddr2,
                registeredCity: this.state.registeredCity,
                registeredZip: this.state.registeredZip,
                registeredAddrIso: this.state.registeredIso,
                operAddress1: this.state.operatingAddr1,
                operAddress2: this.state.operatingAddr2,
                operCity: this.state.operatingCity,
                operZip: this.state.operatingZip,
                operAddrIso: this.state.operatingIso,
                shareHolder: (jsonObj.shareHolder === undefined) ? "" : jsonObj.shareHolder,
            }
            Cookies.set("pmx_progress", JSON.stringify(companyInfo), { expires: 365 })
            const newSavedData = Cookies.get("pmx_progress")
            jsonObj = JSON.parse(newSavedData)
            this.setState({ showLoader:true })
            const mraInfo = {
                "monthlyTransaction": jsonObj.mra1,
                "monthlyVolumes": "EUR " + jsonObj.mra2,
                "serviceDelivered": jsonObj.mra3,
                "locationSettlementBank": jsonObj.bankLocation,
                "mcc":jsonObj.mccCode
            }
            const regAddr = {
                "addressLine1": jsonObj.registeredAddress1,
                "addressLine2": jsonObj.registeredAddress2,
                "city": jsonObj.registeredCity,
                "postCode": jsonObj.registeredZip,
                "state": jsonObj.registeredCity,
                "country": (jsonObj.registeredAddrIso.length !== 2) ? Cookies.get("pmx_iso") : jsonObj.registeredAddrIso,
            }
            const operAddr = {
                "addressLine1": jsonObj.operAddress1,
                "addressLine2": jsonObj.operAddress2,
                "city": jsonObj.operCity,
                "postCode": jsonObj.operZip,
                "state": jsonObj.operCity,
                "country": (jsonObj.operAddrIso.length !== 2) ? Cookies.get("pmx_iso") : jsonObj.operAddrIso,
            }
            const addr = {
                "registeredLocation": regAddr,
                "operatingLocation": operAddr
            }
            const requestBody = {
                "isSoleTrader": (jsonObj.companyType === "1") ? true : false,
                "registeredCountry": jsonObj.registeredIso,
                "typeOfIndustry": jsonObj.naceCode,
                "dateOfRegistration": jsonObj.registerDate,
                "registryNumber": (jsonObj.registeredNumber === undefined || jsonObj.registeredNumber.length === 0) ? jsonObj.vatTax : jsonObj.registeredNumber,
                "vatTaxNumber": jsonObj.vatTax,
                "CompanyName": jsonObj.companyName,
                "Website": jsonObj.companyWeb,
                "TradingAs": jsonObj.companyTradeName,
                "mraInfo": mraInfo,
                "addressInfo": addr,
                "IsCardRequired": jsonObj.needCard,
                "IBAN": jsonObj.bankIban,
                "creditSafeId":(jsonObj.creditSafeId === undefined) ? "N/A" : jsonObj.creditSafeId
            }
            const config = {
                headers: {
                    Authorization: `Bearer ${Cookies.get("pmx_token")}`,
                    'Accept-Language':Cookies.get("pmx_lang")
                }
            }
            console.log("config")
            console.log(config)
            console.log("requestBody")
            console.log(requestBody)
            axiosPersona.post("BusinessInformation", requestBody, config)
            .then(res => {
                companyInfo.entityId=res.data.payload[0].value
                companyInfo.page=redirect
                Cookies.set("pmx_progress", JSON.stringify(companyInfo), { expires: 365 })
                this.setState({ showLoader:false, redirect: true, redirectTo: redirect })
            })
            .catch(err => {
                if (err.code === 'ECONNABORTED') {
                    this.setState({ showErrModal: true, modalErr: "We have encountered an error. Kindly inform support with error code: 15 ", showLoader: false })
                } else {
                    if (err.response && err.response.data && err.response.data.invalidFields && err.response.data.invalidFields.length > 0) {
                        this.setState({ showErrModal: true, modalErr: err.response.data.invalidFields[0], showLoader: false })
                    } else {
                        this.setState({ showErrModal: true, modalErr: "An error occured ", showLoader: false })
                    }
                }
            })
        }
    }
    render() {
        if (this.state.redirect) {
            if (this.state.gotoHome) {
                return (<Navigate to='/' replace={true} />)
            } else {
                return (<Navigate to={this.state.redirectTo} replace={true} />)
            }
        }
        const { t } = this.props
        let screenDataRegestered = (
            <>
                <div>
                    <label className="form-label poppins-regular ">{t('LBL_addr1')}</label>
                    <input type="text" className="form-control" value={this.state.registeredAddr1} onChange={(e) => this.setState({ registeredAddr1: e.target.value, operatingAddr1: e.target.value })} />
                </div>
                <div>
                    <label className="form-label poppins-regular ">{t('LBL_addr2')}</label>
                    <input type="text" className="form-control" value={this.state.registeredAddr2} onChange={(e) => this.setState({ registeredAddr2: e.target.value, operatingAddr2: e.target.value })} />
                </div>
                <div>
                    <label className="form-label poppins-regular ">{t('LBL_addr3')}</label>
                    <input type="text" className="form-control" value={this.state.registeredCity} onChange={(e) => this.setState({ registeredCity: e.target.value, operatingCity: e.target.value })} />
                </div>
                <div>
                    <label className="form-label poppins-regular ">{t('LBL_addr4')}</label>
                    <input type="text" className="form-control" value={this.state.registeredZip} onChange={(e) => this.setState({ registeredZip: e.target.value, operatingZip: e.target.value })} />
                </div>
                <div className='mt-4'>
                    <label className="form-label poppins-regular ">{t('LBL_addr_iso')}</label>
                    <Select
                        styles="form-control"
                        clicked={(value) => { this.setState({ registeredIso: value, operatingIso: value }) }}
                        selectedItem={this.state.registeredIso}
                        options={countryData1()}
                    />
                </div>
            </>
        )
        let screenDataOperating = (
            <>
                <div>
                    <label className="form-label poppins-regular ">{t('LBL_addr1')}</label>
                    <input type="text" className="form-control" value={this.state.operatingAddr1} onChange={(e) => this.setState({ operatingAddr1: e.target.value })} />
                </div>
                <div>
                    <label className="form-label poppins-regular ">{t('LBL_addr2')}</label>
                    <input type="text" className="form-control" value={this.state.operatingAddr2} onChange={(e) => this.setState({ operatingAddr2: e.target.value })} />
                </div>
                <div>
                    <label className="form-label poppins-regular ">{t('LBL_addr3')}</label>
                    <input type="text" className="form-control" value={this.state.operatingCity} onChange={(e) => this.setState({ operatingCity: e.target.value })} />
                </div>
                <div>
                    <label className="form-label poppins-regular ">{t('LBL_addr4')}</label>
                    <input type="text" className="form-control" value={this.state.operatingZip} onChange={(e) => this.setState({ operatingZip: e.target.value })} />
                </div>
                <div className='mt-4'>
                    <label className="form-label poppins-regular ">{t('LBL_addr_iso')}</label>
                    <Select
                        styles="form-control"
                        clicked={(value) => { this.setState({ operatingIso: value }) }}
                        selectedItem={this.state.operatingIso}
                        options={countryData1()}
                    />
                </div>
            </>
        )
        if (this.state.showOperating) {
            screenDataRegestered = null
        } else {
            screenDataOperating = null
        }
        return (
            <Layout>
                <ApiError isSuccess={false} show={this.state.showErrModal} modalClosed={() => this.setState({ showErrModal: false })} modalType="Alert1" btnName={t('BTN_close')}>
                    <div className="p-3">
                        {this.state.modalErr}
                    </div>
                </ApiError>
                {this.state.showLoader ? (
                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center H100'>
                        <Loader />
                    </div>
                ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center align-self-center">
                        <div className='W100 mt-4'>
                            <Step active={2} step={2} />
                        </div>
                        <div className='W100 pt-2 ps-4 pe-4'>
                            <h1 className='poppins-extrabold'>{t('BP_title4_h1')}</h1>
                            <p className='poppins-regular mt-4 mb-4'>{t('BP_info4_p')}</p>
                            {screenDataRegestered}
                            <div className='mt-4'>
                                <Checkbox clicked={(val) => this.operatingAddress(val)}>{t('LBL_is_operating')}</Checkbox>
                            </div>
                            {screenDataOperating}
                            <Button clicked={() => this.validateForm()} btnType={"mt-5 ButtonLogin"} type="submit">{t('BTN_next')}</Button>
                            <p className='linkBtn poppins-bold pointer' onClick={() => this.goBack()} >{t('LINK_back')}</p>
                        </div>
                    </div>
                )}
            </Layout>
        )
    }
}

export default withTranslation()(Screen4)